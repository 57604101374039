import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FingerprintSharpIcon from '@material-ui/icons/FingerprintSharp';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import firebase from "firebase/app";
import 'firebase/auth';
import { useHistory } from 'react-router-dom';
import urls from '../common/urls';
import {
  FINAL_CALL_SUBSCRIPTION_URL,
  PASSWORD_INVALID,
  EMAIL_INVALID_ERROR_MESSAGE,
  PASSWORDS_ARE_NOT_THE_SAME,
  EMAIL_IS_ALREADY_IN_USE
} from '../common/constants';
import { emailIsValid, passwordIsValid } from '../common/helpers';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href={FINAL_CALL_SUBSCRIPTION_URL}>
        Final Call Distribution
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUpComponent() {
  const classes = useStyles();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');
  const [passwordIsInvalid, setpasswordIsInvalid] = useState(false);
  const [confirmPassIsInvalid, setConfirmPassIsInvalid] = useState(false);
  const history = useHistory();

  const runValidation = () => {
    if (!emailIsValid(email)) {
      setEmailIsInvalid(true);
      setEmailErrorText(EMAIL_INVALID_ERROR_MESSAGE);
    } else {
      setEmailIsInvalid(false);
      setEmailErrorText('')
    }

    if (!passwordIsValid(password)) {
      setpasswordIsInvalid(true);
    } else {
      setpasswordIsInvalid(false);
    }

    if (password !== confirmPassword) {
      setConfirmPassIsInvalid(true);
    } else {
      setConfirmPassIsInvalid(false);
    }

    const formValid = !emailIsInvalid && !passwordIsInvalid && !confirmPassIsInvalid;
    if (formValid) {
      register();
    }
  }

  const register = () => {
    firebase.auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        history.push(urls.USER);
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          setEmailErrorText(EMAIL_IS_ALREADY_IN_USE);
          setEmailIsInvalid(true);
        }
      });
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <FingerprintSharpIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={emailIsInvalid}
                helperText={emailErrorText}
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={passwordIsInvalid}
                helperText={passwordIsInvalid ? PASSWORD_INVALID : ''}
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="confirm-current-password"
                error={confirmPassIsInvalid}
                helperText={confirmPassIsInvalid ? PASSWORDS_ARE_NOT_THE_SAME : ''}
                onChange={e => setConfirmPassword(e.target.value)}
                value={confirmPassword}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => runValidation()}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};
