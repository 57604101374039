import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyA49XWXwbNoc0-Dzi-ogSTiCiy5JX-7oCM",
  authDomain: "final-call-distributors.firebaseapp.com",
  databaseURL: "https://final-call-distributors.firebaseio.com",
  projectId: "final-call-distributors",
  storageBucket: "final-call-distributors.appspot.com",
  messagingSenderId: "835992458912",
  appId: "1:835992458912:web:775bd135c048ce374d9bc5",
  measurementId: "G-CCC0JFNL47"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
