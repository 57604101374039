import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FingerprintSharpIcon from '@material-ui/icons/FingerprintSharp';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import urls from '../common/urls';
import firebase from "firebase/app";
import 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { FINAL_CALL_SUBSCRIPTION_URL, PASSWORD_INVALID, PASSWORD_IS_INCORRECT, USER_NOT_FOUND, EMAIL_INVALID_ERROR_MESSAGE } from '../common/constants';
import { emailIsValid, passwordIsValid } from '../common/helpers';
import backgroundPhoto from "../images/fcn-new.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href={FINAL_CALL_SUBSCRIPTION_URL}>
        Final Call Distribution
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${backgroundPhoto})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInComponent() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const history = useHistory();

  const onSubmit = () => {
    const emailValid = emailIsValid(email);
    const passwordValid = passwordIsValid(password);

    if (!emailValid) {
      setEmailIsInvalid(true);
      setEmailHelperText(EMAIL_INVALID_ERROR_MESSAGE);
    } else {
      setEmailIsInvalid(false);
      setEmailHelperText('');
    }

    if (!passwordValid) {
      setPasswordIsInvalid(true);
      setPasswordHelperText(PASSWORD_INVALID)
    } else {
      setPasswordIsInvalid(false)
      setPasswordHelperText('')
    }

    if (emailValid && passwordValid) {
      firebase.auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push(urls.USER);
      })
      .catch((error) => {
        if (error.code === "auth/wrong-password") {
          setPasswordHelperText(PASSWORD_IS_INCORRECT);
          setPasswordIsInvalid(true);
        }

        if (error.code === "auth/user-not-found") {
          setEmailHelperText(USER_NOT_FOUND);
          setEmailIsInvalid(true);
        }
      });
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <FingerprintSharpIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={emailIsInvalid}
              helperText={emailHelperText}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              error={passwordIsInvalid}
              autoComplete="current-password"
              helperText={passwordHelperText}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onSubmit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href={urls.FORGOT_PASSWORD} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href={urls.SIGNUP} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}