import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import firebase from "firebase/app";
import 'firebase/database';
import 'firebase/auth';
import { useHistory } from 'react-router-dom';
import urls from '../common/urls';
import QRCode from 'qrcode.react';
import { FIELD_IS_REQUIRED } from "../common/constants";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: 'white'
  },
  gridContainer: {
    width: '100vw',
    height: '80vh'
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  qrCodeStyle: {
    marginTop: 25
  },
  copyButton: {
    marginTop: 25,
    marginBottom: 25
  },
  formPadding: {
    padding: 10
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  signOutButton: {
    position: 'absolute',
    right: 10
  },
  formLabel: {
    textAlign: 'center'
  }
}));

export default function UserComponentPage() {
  const history = useHistory();
  const classes = useStyles();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [nationId, setNationId] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [codeGenerated, setCodeGenerated] = useState(false);
  const [userLink, setUserLink] = useState('');
  const [validation, setValidation] = useState({
    firstNameValid: true,
    lastNameValid: true,
    cityValid: true,
    stateValid: true,
    nationIdValid: true
  });


  useEffect(() => {
    const fetchData = async () => {
      const user = await firebase.auth().currentUser;
      if (!user) {
        history.push(urls.SIGNIN);
      } else {
        setCurrentUser(user);
        setUserLink(`https://finalcallsubscriber.com${urls.SUBSCRIBER}/${user.uid}`);

        if (isLoading) {
          const db = await firebase.database().ref('users/' + user.uid);
          db.on("value", function (snapshot) {
            if (snapshot.val()) {
              const { firstName, lastName, city, state, nationId, codeGenerated } = snapshot.val();
              setFirstName(firstName);
              setLastName(lastName);
              setCity(city);
              setState(state);
              setNationId(nationId);
              setCodeGenerated(codeGenerated)
              setIsLoading(false);
            }
          }, function (errorObject) {
            console.log("The read failed " + errorObject.code);
          });
        }
      }
    }
    fetchData();
  });

  const onSignoutClick = () => {
    firebase.auth().signOut().then(() => {
      history.push(urls.SIGNIN);
    });
  }

  const runValidation = () => {
    setValidation({
      firstNameValid: isPopulated(firstName),
      lastNameValid: isPopulated(lastName),
      cityValid: isPopulated(city),
      stateValid: isPopulated(state),
      nationIdValid: isPopulated(nationId)
    });

    if (isFormValid()) {
      onGenerateQRCodeClicked();
    }
  }

  const isPopulated = (value) => {
    return value.length > 0;
  }

  const isFormValid = () => {
    return isPopulated(firstName) &&
      isPopulated(lastName) &&
      isPopulated(city) &&
      isPopulated(state) &&
      isPopulated(nationId);
  }

  const onGenerateQRCodeClicked = () => {
    firebase.database().ref('users/' + currentUser.uid).set({
      firstName: firstName,
      lastName: lastName,
      nationId: nationId,
      email: currentUser.email,
      city: city,
      state: state,
      codeGenerated: true
    });
  }

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      console.log("Copy successful");
    } catch (err) {
      console.log("Failed to copy")
    }
  }

  return (
    <>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Button
            className={classes.signOutButton}
            onClick={onSignoutClick}
          >
            Sign Out
          </Button>
        </Toolbar>
      </AppBar>
      <main className={classes.main}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.gridContainer}
          style={{ width: '100vw', height: '80vh' }}
        >
          <Grid className={classes.formPadding} item xs={12} sm={6} md={6} component={Paper} elevation={0}>
            <Typography className={classes.formLabel} component="h1" variant="h5">
              Account Details
          </Typography>
            <form>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="firstName"
                autoFocus
                value={firstName}
                error={!validation.firstNameValid}
                helperText={!validation.firstNameValid ? FIELD_IS_REQUIRED : ''}
                onChange={e => setFirstName(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="lastName"
                label="Last Name"
                type="lastName"
                id="lastName"
                autoComplete="last-name"
                value={lastName}
                error={!validation.lastNameValid}
                helperText={!validation.lastNameValid ? FIELD_IS_REQUIRED : ''}
                onChange={e => setLastName(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="city"
                label="City"
                type="city"
                id="city"
                autoComplete="city"
                value={city}
                error={!validation.cityValid}
                helperText={!validation.cityValid ? FIELD_IS_REQUIRED : ''}
                onChange={e => setCity(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="state"
                label="State"
                type="state"
                id="state"
                autoComplete="state"
                value={state}
                error={!validation.stateValid}
                helperText={!validation.stateValid ? FIELD_IS_REQUIRED : ''}
                onChange={e => setState(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="nationId"
                label="Nation Id"
                type="nationId"
                id="nationId"
                autoComplete="nation-id"
                value={nationId}
                error={!validation.nationIdValid}
                helperText={!validation.nationIdValid ? FIELD_IS_REQUIRED : ''}
                onChange={e => setNationId(e.target.value)}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={runValidation}
              >
                Generate QR Code
            </Button>
            </form>
          </Grid>
          <Grid item xs={12} sm={5} md={6} component={Paper} style={{ textAlign: 'center', zIndex: 0 }} square elevation={0}>
            {codeGenerated ?
              <>
                <div>
                  <QRCode
                    value={userLink}
                    size={250}
                    className={classes.qrCodeStyle}
                  />
                </div>
                {document.queryCommandSupported('copy') &&
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => copyToClipBoard(userLink)}
                      className={classes.copyButton}
                    >
                      Copy Link
                    </Button>
                  </div>
                }

              </>
              :
              null
            }

          </Grid>
        </Grid>
      </main>
    </>
  );
}
