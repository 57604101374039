import React, { useState,  } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import firebase from "firebase/app";
import 'firebase/database';
import 'firebase/auth';
import moment from 'moment';
import { emailIsValid } from '../common/helpers';
import { EMAIL_SUBSCRIBER_EXISTS_ERROR_MESSAGE, EMAIL_INVALID_ERROR_MESSAGE, FINAL_CALL_SUBSCRIPTION_URL } from '../common/constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SubscriberComponent() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const { uid } = useParams();
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [helperText, setHelperText] = useState('');

  const submitSubscribersEmail = () => {
    if (!emailIsValid(email)) {
      setEmailIsInvalid(true);
      setHelperText(EMAIL_INVALID_ERROR_MESSAGE);
      return;
    } else {
      setEmailIsInvalid(false);
    }

    firebase.auth().signInAnonymously()
      .then(() =>{
        const ref = firebase.database().ref('/customers/' + uid);
        ref.once('value').then(function(snapshot) {
          const subscriberEmailList = snapshot.val();
          let emailIsNotInList = true;

          for (const key in subscriberEmailList) {
            const subscriberEmail = subscriberEmailList[key].email.toLowerCase().trim();
            if (subscriberEmail === email.toLowerCase().trim()) {
              
              emailIsNotInList = false;
              break;
            }
          }
          
          if (emailIsNotInList) {
            setHelperText('');
            setEmailIsInvalid(false);
            ref.push({
              email,
              dateCreated: moment().format('MM/DD/YYYY h:mm:ss a')
            }).then(() => {
              window.location.href = FINAL_CALL_SUBSCRIPTION_URL
            });
          } else {
            setHelperText(EMAIL_SUBSCRIBER_EXISTS_ERROR_MESSAGE);
            setEmailIsInvalid(true);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Become a subscriber!
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={e => setEmail(e.target.value)}
                value={email}
                error={emailIsInvalid}
                helperText={helperText}
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitSubscribersEmail}
          >
            Sign Up
          </Button>
        </form>
      </div>
    </Container>
  );
}