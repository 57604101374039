import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import SignInComponent from './components/SignInComponent';
import SignUpComponent from './components/SignUpComponent';
import ForgotPasswordComponent from './components/ForgotPasswordComponent';
import UserPageComponent from './components/UserPageComponent';
import SubscriberComponent from './components/SubscriberComponent';
import urls from './common/urls';
import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={`${urls.SUBSCRIBER}/:uid`}>
          <SubscriberComponent />
        </Route>
        <Route exact path={urls.FORGOT_PASSWORD}>
          <ForgotPasswordComponent />
        </Route>
        <Route exact path={urls.USER}>
          <UserPageComponent />
        </Route>
        <Route exact path={urls.SIGNUP}>
          <SignUpComponent />
        </Route>
        <Route exact path={urls.SIGNIN}>
          <SignInComponent />
        </Route>
      </Switch>
    </Router>
  )
}

export default App;
